import { Routes } from '@angular/router';
import { ComponentsComponent } from '../../components/components.component'
import { AdminGuard } from '../guard/admin.guard';

export const CommonLayout_ROUTES: Routes = [

    //Dashboard
    {
        canActivate: [AdminGuard],
        data: {
            title: 'قسم لوحة القيادة'
        },
        path: 'dashboard',
        loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
    },

    //Modules
    {
        canActivate: [AdminGuard],
        data: {
            title: 'قسم تحسين محركات البحث'
        },
        path: 'seo',
        loadChildren: () => import('../../modules/modules.module').then(m => m.ModulesModule),
    },

  
];
