import { SideNavInterface } from "../../interfaces/side-nav.type";

export const ROUTES_CUSTOMER_DASHBOARD: SideNavInterface[] = [
  {
    path: "/dashboard",
    title: "لوحة التحكم",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "dashboard",
    submenu: [],
  },
  {
    path: "/seo/settings",
    title: "إعدادات تحسين محركات البحث",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "seo",
    submenu: [],
  },
  {
    path: "/seo/article-list",
    title: "المقالات",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "article",
    submenu: [],
  },
  {
    path: "/seo/category-list",
    title: "فئات المقالات",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "product",
    submenu: [],
  },

  {
    path: "/seo/image-link-generator",
    title: "مولد رابط الصور",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "product",
    submenu: [],
  },
];
